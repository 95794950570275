import styled from "styled-components";
const NavbarDisplay = styled.div`
  .nav {

    background: var(--secondaryBgColor);
    display: flex;
    justify-content: space-between;
    align-items: center !important;
   //padding: 20px 25px 15px 25px;
  // padding: 20px 19px 15px 19px;
  padding-inline: 20px;
    position: ;
    position: fixed;
    top: 0;
    width: 88%;
    z-index: 4;
    height: 61px;
    // height : var(--navHeight);
    margin : var(--marginNav);
   maxWidth:1150
  
    }
    .nav_yMobile
    {
      padding: 5px 25px 3px 25px;
      height :90px;
    }

    .nav_scroll
    {
      background-color: var(--BgNavScroll);
      opacity: var(--opacityScroll);
      margin:var(--marginScroll);
    }
  @media(min-width: 450px){
    .nav {
      width: 92%;
    }
  }
  @media(min-width: 700px){
    .nav {
      width: 95%;
    }

  
  }
  @media(min-width: 600px){
    .nav {
      width: 93%;
    }
  }
  .navHight{
    // height:61px;
    height: var(--navHight);
  }
`;
export default NavbarDisplay;
