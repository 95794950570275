import React, { Component } from "react";
import { Trans, withTranslation } from "react-i18next";
import ZACatchPageDisplay from "./ZACatchPage.styled";
import axios from "axios";
import queryString from "query-string";

import  { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from 'react-router-dom';
import img from "../../assets/images/complete.svg";



function ZACatchPage ({ t }) {
  const [errorMsg, seterrorMsg] = useState("");
  const [errsubMsg, seterrsubMsg] = useState("");
  const [errors, seterrors] = useState(false);
  const [errsub, seterrsub] = useState(false);
  const [verify_errors, setverify_errors] = useState(false);
  const [show, setshow] = useState(true);
  const [loading, setloading] = useState(false);
  const [validcode, setvalidcode] = useState(false);

  const [phonePlaceholder, setphonePlaceholder] = useState("enter_phone_number");
  const history = useHistory();
  useEffect(() => {
    fireAPI();
  } , [])



  async function fireAPI()  {


    const data = queryString.parse(window.location.search)
   //console.log(data)
    if (data.msisdn) {
      const res = await axios.get(`http://www.api.playit.mobi/api/v2/mondia-za/get-sub-details?msisdn=${data.msisdn}&plan_id=${data.plan_id}&code=${data.code}&redirect_type=subscription`)
        .then(res => {
        }).catch(error => {
         
        }
        )
    } else {
    }
  }
  async function login  () {
    const data = queryString.parse(window.location.search)

    var url = `http://www.api.playit.mobi/api/v2/mondia-za/get-login-url?msisdn=${data.msisdn}`;
    axios.get(url )
      .then(res => {
        if (res.data.status === "success") {
          window.location.href = res.data.data.url
        }
        else {
          seterrorMsg(res.data.data)
          seterrors(true);
         
        }
        setloading(false)

      })
      .catch(function (error) {
       console.log(error)
      })
      .finally(function () {
       //console.log('complete');
      });

    }
  
  



    return (
      <ZACatchPageDisplay className="">
       <div className="formPage">
          <form
         
          >
            <div className="formsLayout" style={{ minHeight: '45vh' }}>
              <div className="header">
                <Link to="/">
                  <div className="logo" />
                </Link>
              </div>
         
              <div className="center">
              <img src={img} alt="Complete" />
            </div>

            </div>
          </form>


          <div className="footer">
            <div className="normBtn">
              <button className="btn" onClick={e => login()} >
                <Trans>sign_in</Trans>
              </button>
            </div>



            
          </div>
          {/* {errors ? <FloatingAlert type="error"><Trans>{errorMsg}</Trans></FloatingAlert> : ""}
          {errsub ? <FloatingAlert type="error"><Trans>{errsubMsg}</Trans></FloatingAlert> : ""}
 */}

        </div>

      </ZACatchPageDisplay>
    );
  
}
export default withTranslation()(ZACatchPage);
