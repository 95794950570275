import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import FloatingAlert from "../../../components/FloatingAlert/FloatingAlert";
import bgSliders from "../../../helpers/bgSliders";
import "./SignupPage.styled";
import ZASignupPageDisplay from "./SignupPage.styled";
import { setLoginData } from "../../../helpers/helpers";
import { Trans, withTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { setAgencyData, saveTracker } from "../../../helpers/agencyTracker";
import queryString from "query-string";



const randomBg = bgSliders.getRandomBg();
function ZASignupPage({ t }) {
  const [activeData, setActiveData] = useState("");
  const [checkPhone, setCheckPhone] = useState(false);

  const [showverif, setShowverif] = useState(false);
  const [verifyCode, setVerifyCode] = useState(false);
  const [errorMesg, setErrorMesg] = useState(false);
  const [verfiErrorMesg, setVerfiErrorMesg] = useState(false);
  const [errorresverify, seterrorresverify] = useState("");

  const [errorMesgSubscribe, setErrorMesgSubscribe] = useState("");
  const [signupPlan, setSignupPlan] = useState("d");
  const [otpid, setotpid] = useState("");
  const [showLoading, setshowLoading] = useState(false);
  const [transactionidentify,setTransactionidentify] = useState("");
  const [transactionid,setTransactionid] = useState("");


  const history = useHistory();
  const [alert, setAlert] = useState(false);
  const params = queryString.parse(window.location.search);
  const [btn, setbtn] = useState(false);


 
  const [randomString, setRandomString] = useState('');

  const generateRandomString = (length) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  };
  const changeBtn = (e) => {
    setbtn(true);
  };
  useEffect(() => {
   
  }, []);

 
  const redirect = async e => {
    e.preventDefault();
    if (activeData) {
      var phon_n = activeData.toString();
      var url = "";
      url = `http://www.api.playit.mobi/api/v2/mondia-za/get-redirect-url?msisdn=${phon_n}`
      setshowLoading(true);
      axios.get(url)
        .then(res => {
          if (res.data.status !== 'failed') {
            
            window.location.href = res.data.data.url;
          }
          else {
            if (res.data.msg != null) {
              setErrorMesgSubscribe(res.data.msg);
              setErrorMesg(true);


            }
            else {
              setErrorMesgSubscribe(t("register.SUBSCRIBE_ERROR"));
              setErrorMesg(true);


            }
             //setShowverif(true)

             //setshowLoading(false);
          }
          setshowLoading(false);
          //setShowverif(true)
        })
        .catch(function (error) {
          setErrorMesg(true)
          setShowverif(false)
        })
        .finally(function () {
         //console.log('complete');
        });


    }
  };
 
 
 






  return (
    <ZASignupPageDisplay className="">
     {alert && (
      <FloatingAlert type={"danger"}>
        In order to signup please turn your mobile data.
      </FloatingAlert>
    )}

    {errorMesg ? (
      <FloatingAlert type={"error"}>
        <Trans>{errorMesgSubscribe}</Trans>
      </FloatingAlert>
    ) : null}
     

    

    <div className='operatorSignup'>
      <div className="formsLayout formsLayoutjawal">

        <div className='header header_gamezone'>
          <Link to="/">
            <div className='logo logo gamezoneLogo' />
          </Link>
        </div>
    
        
  
    <div className="links-Section links-Sectionjawwalgamezorid">
      <h3 style={{ paddingBottom: '1px' }} className="gamezoridcontent" id="gamezoridcon">
        <Trans>Your content is ready!</Trans>
      </h3>
      <div className="play"></div>
      <div className="number-inputs">
        <input
          type="text"
          className="input gamezoneInput"   
          style={{ fontSize: '12px' }}
          placeholder={t("Enter_your_phone_number")}
          value={activeData}
          disabled={checkPhone === true}
          onChange={(e) => setActiveData(e.target.value.replace(/[^0-9]/g, ""))}
        />
      </div>
      <button
        id="sub"
        className="btn"
        disabled={!activeData}
        onClick={(e) => { redirect(e) }}
      >
        {t("Subscribe")}
      </button>
      
      <div className="row text-center">
        <div className="col-xl-4 col-lg-4 col-4 px-5">
          <div className="games-info jawwalgames-info">
            <span>98%</span>
            <img src="/assets/images/subscribegamezriod/like.png" alt="img" className="iconsimg" />
            <p><Trans>evaluation</Trans></p>
          </div>
        </div>
        <div className="col-xl-4 col-lg-4 col-4 px-5">
          <div className="games-info jawwalgames-info">
            <span>1000+</span>
            <img src="/assets/images/subscribegamezriod/Repeat.png" alt="img" className="iconsimg" />
            <p><Trans>games</Trans></p>
          </div>
        </div>
        <div className="col-xl-4 col-lg-4 col-4 px-5">
          <div className="games-info jawwalgames-info">
            <span>500k+</span>
            <img src="/assets/images/subscribegamezriod/group.png" alt="img" className="iconsimg" />
            <p><Trans>users</Trans></p>
          </div>
        </div>
      </div>
      
      <div className="footer jawwalgamezoridFooter zainiqFooter">
        {!showverif ? <div></div> : null}
        <div className="ncellTos jawwalgamezoridncellTos">
          <h3><Trans>terms_and_conditions</Trans></h3>
          <p className="text-center"><Trans>ZA-condtion</Trans></p>
        </div>
      </div>
    </div>
  


        {showLoading ?
          <div className="load">
            <div className="spinerC">
              <div className="spinner"></div>
            </div>
          </div>
          : null}




      </div>
    </div>
      

    </ZASignupPageDisplay>
  );
}
export default withTranslation()(ZASignupPage)
