import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Trans, withTranslation } from "react-i18next";
import "./LibyanaMarketing2.css";
import axios from "axios";

import img1 from "../../../assets/images/freepik__10-or-more-games-characters-in-gaming-world__94690.png";
import img3 from "../../../assets/images/worrior.png";
import img4 from "../../../assets/images/spinner.037752c6.png";
import FloatingAlert from "../../../components/FloatingAlert/FloatingAlert";
import queryString from "query-string";
import { setAgencyData } from "../../../helpers/agencyTracker";

const setLoginData = React.lazy(() =>
  import("../../../helpers/helpers").then((module) => ({
    default: module.setLoginData,
  }))
);
const saveTracker = React.lazy(() =>
  import("../../../helpers/agencyTracker").then((module) => ({
    default: module.saveTracker,
  }))
);
function LibyanaMarketing2({ t }) {
  const [showLoading, setshowLoading] = useState(false);
  const [activeData, setActiveData] = useState("");
  const [transactionidentify, setTransactionidentify] = useState("");
  const [showverif, setShowverif] = useState(false);
  const [errorMesgSubscribe, setErrorMesgSubscribe] = useState("");
  const [errorMesg, setErrorMesg] = useState(false);
  const [verfiErrorMesg, setVerfiErrorMesg] = useState(false);
  const [verifyCode, setVerifyCode] = useState("");
  const [errorresverify, seterrorresverify] = useState("");
  const history = useHistory();

  const [otpid, setotpid] = useState("");

  function gtag_report_conversion() {
    let tracker_value = localStorage.getItem("tracker");
    if (localStorage.getItem(tracker_value) != "1") {
      window.gtag("event", "conversion", {
        send_to: "AW-16646980382/iigcCJ6GvuIZEJ6G84E-",
      });
      localStorage.setItem(tracker_value, "1");
    }
    return false;
  }

  let currentURL = window.location.href;
  let BaseURL = "https://api.playit.mobi/api/";
  const isHttps = currentURL.startsWith("https://");
  const params = queryString.parse(window.location.search);

  const Subscribe = async (e) => {
    //console.log(e.target.phone_number.value);
    setActiveData(e.target.phone_number.value);
    const phone_number_val = e.target.phone_number.value;
    //console.log(activeData);
    e.preventDefault();

    if (phone_number_val) {
      var phon_n = phone_number_val.toString();

      if (currentURL.startsWith("https://")) {
        BaseURL = "https://apissl.playit.mobi/api/";
      }
      let url = BaseURL + `v2/connex-libya/send-otp-login`;

      const options = {
        msisdn: phon_n,
        transaction_identify: transactionidentify,
      };

      setshowLoading(true);
      await axios
        .post(url, options)
        .then((res) => {
          if (res.data.status === true) {
            if (res.data.user_id) {
              setLoginData(
                res.data.user_id,
                res.data.token,
                res.data.user_name,
                res.data.photo,
                res.data.operator_id,
                res.data.user_email,
                res.data.msisdn
              );
            }
            if (res.data.otpid) {
              setotpid(res.data.otpid);
            }
            setShowverif(true);
          } else {
            if (res.data.msg != null) {
              setErrorMesgSubscribe(res.data.msg);
              setErrorMesg(true);
            } else {
              setErrorMesgSubscribe(t("register.SUBSCRIBE_ERROR"));
              setErrorMesg(true);
            }
            // setShowverif(true)
          }
          setshowLoading(false);
        })
        .catch(function (error) {
          setErrorMesg(true);
          setShowverif(false);
        })
        .finally(function () {
          //console.log('complete');
        });
    }
  };
  const tracker = async (GMSISDN) => {
    var datag = {};
    if (localStorage.getItem("tracker")) {
      var op_id = "CcoIV1eQFA";
      if (
        GMSISDN.startsWith("21891") ||
        GMSISDN.startsWith("21893") ||
        GMSISDN.startsWith("91") ||
        GMSISDN.startsWith("93")
      ) {
        op_id = "qiL2bqfIL3";
      }

      datag = {
        key: "parameter",
        company: localStorage.getItem("trackerCompany"),
        value: localStorage.getItem("tracker"),
        identifierKey: "id",
        identifierValue: op_id,
        phoneNum: GMSISDN,
      };
      //console.log(datag)
    }
    await saveTracker(datag);
  };
  const verify = async (e) => {
    e.preventDefault();
    if (verifyCode) {
      var phon_n = activeData;
      setshowLoading(true);
      if (currentURL.startsWith("https://")) {
        BaseURL = "https://apissl.playit.mobi/api/";
      }

      let url = BaseURL + "v2/connex-libya/login-confirm";

      axios
        .get(url + `?msisdn=${phon_n}&pincode=${verifyCode}&plan=w`)
        .then((res) => {
          //console.log(res);
          if (res.data.status === true) {
            setVerfiErrorMesg(false);
            //tracker(phon_n);
            if (res.data.data.user_id) {
              if (res.data.data.subscription_status) {
                tracker(phon_n);
                gtag_report_conversion();

                //setLoginData(res.data.user_id, res.data.token, res.data.user_name, res.data.photo, res.data.operator_id, res.data.user_email, res.data.msisdn);

                window.location.href =`https://libya.playit.mobi/catch-Libyana?msisdn=${res.data.data.username}`;
              } else {
                seterrorresverify(res.data.data.message);
                setVerfiErrorMesg(true);
              }
              //setLoginData(res.data.user_id, res.data.token, res.data.user_name, res.data.photo, res.data.operator_id, res.data.user_email, res.data.msisdn);
              //history.push("/catch-Libyana?msisdn="+res.data.data.username);
            } else {
              if (res.data.message != null) {
                seterrorresverify(res.data.msg);
                setVerfiErrorMesg(true);
              }
            }
            //  window.location.href = "/";
            //history.push("/catch-Libyana")
          } else {
            if (res.data.msg != null) {
              seterrorresverify(res.data.msg);
              setVerfiErrorMesg(true);
            } else {
              // setErrorMesgSubscribe(t("register.SUBSCRIBE_ERROR"));
              seterrorresverify(t("register.VERIFY_ERROR"));
              setVerfiErrorMesg(true);
            }
            // setVerfiErrorMesg(true);
            // seterrorresverify(res.data.msg);
          }
          setshowLoading(false);
        })
        .catch(function (error) {
          //console.log(error);
          // setVerfiErrorMesg(true);
          // seterrorresverify(res.data.msg);
        })
        .finally(function () {
          //console.log('complete');
        });
    }
  };
  async function headerSignup() {
    //e.preventDefault();
    //console.log("header Subscribe");
    if (currentURL.startsWith("https://")) {
      BaseURL = "https://apissl.playit.mobi/api/";
    }

    var url =
      BaseURL +
      `v2/connex-libya/get-protected-script?targeted_element=%23cta_button`;
    //setshowLoading(true);
    axios
      .get(url)
      .then((res) => {
        //console.log(res);
        //console.log(res.data.status);
        if (res.data.status === true) {
          const addScript = document.createElement("script");
          // addScript.id = "addScript";
          addScript.innerHTML = res.data.dcbprotect;
          document.head.appendChild(addScript);
          setTransactionidentify(res.data.transaction_identify);
          setshowLoading(false);
          var event = new Event("DCBProtectRun");
          document.dispatchEvent(event);
          //setShowverify(true)
          // window.location.href = res.data.url
          // window.location.href = 'http://login.mondiamediamena.com/billinggw-lcm/billing?merchantId=197&operatorId=22&redirect=http://dzo.playit.mobi/mondiaverify/W&method=subscribe&imgPath=http%3A%2F%2Fapi.playit.mobi%2Fgames-photos%2Fmondia.png&productCd=PLAYITALGERIA&subPackage=W';
        } else {
          setErrorMesg(true);
          setErrorMesgSubscribe(res.data.message);
          // setshowagin(true)
        }

        setshowLoading(false);
      })
      .catch(function (error) {
        // setErrorMesg(true)
      })
      .finally(function () {
        //console.log('complete');
      });
  }

  useEffect(() => {
    if (isHttps) {
      BaseURL = "https://apissl.playit.mobi/api/";
    }
    localStorage.setItem("i18nextLng", params.lang || "ar");
    headerSignup();
    if (window.location.search) setAgencyData(window.location.search);
  }, [isHttps, params.lang]);
  return (
    <>
      {errorMesg ? (
        <FloatingAlert type={"error"}>
          <Trans>{errorMesgSubscribe}</Trans>
        </FloatingAlert>
      ) : null}
      {verfiErrorMesg ? (
        <FloatingAlert type={"error"}>
          <Trans>{errorresverify}</Trans>
        </FloatingAlert>
      ) : null}

      <div className="LibyanaMarketing2 ">
        <div className="  marketingcontent  ">
          <div className="mb-4  ">
            <Link className="gamezoneLogo" to="/">
              <div className="logo   cptpl_logo" alt="service logo" />
            </Link>
          </div>
          <>
            <div className="container position-relative">
              <svg
                viewBox="0 0 100 100"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                preserveAspectRatio="none"
              >
                <path
                  d="M0 5 L10 5 L15 0 L94.9 0 L99.9 15 L99.9 100 L85 100 L80 95 L5 95 L0 85 L0 5 Z"
                  vectorEffect="non-scaling-stroke"
                ></path>
              </svg>

              <img className="worrior" src={img3} alt="img1" />
              <img className="toyspinner" src={img4} alt="img1" />

              <div className="sub-details position-relative text-center">
                <img className="coverImg mt-3" src={img1} alt="img1" />
                {!showverif ? (<>
                  <div className=" cptpl_service mt-3">
                  <p className="">
                    <Trans>
                      Playit offers more than 500 games for game lovers
                    </Trans>
                  </p>
                </div>
                <form
                  className="formsubmit px-2"
                  name="formsubmit"
                  onSubmit={(e) => {
                    e.preventDefault();
                    Subscribe(e);
                  }}
                >
                  <div className="formInput d-flex justify-content-center flex-row-reverse">
                    <input
                      type="text"
                      className="input gamezoneInput gamezonekeyph "
                      value="218"
                      disabled={true}
                    />

                    <input
                      type="text"
                      className="input gamezoneInput gamezonenumph text-start"
                      placeholder="XX XXX XX XX"
                      name="phone_number"
                      // disabled={checkPhone === true}
                      //onChange={(e) => setActiveData(e.target.value.replace(/[^0-9]/g, ""))}
                    />
                  </div>

                  <button
                  id="cta_button"
                    className="  cptpl_subscribe snip1562"
                    //disabled={activeData ? "" : "disabled"}
                    type="submit"
                  >
                    {t("Continue to subscribe")}
                  </button>
                </form>

                <div className="">
                  <div id="libya-footer" className="mx-5 mt-4">
                    <p id="libya-price" className="cptpl_price">
                      <Trans>libyana-condtion_tandc4</Trans>
                    </p>
                    <p className="cptpl_terms" id="libya-terms">
                      <Trans>libyana-condtion_term</Trans>
                    </p>
                  </div>
                </div>
                </>
               
                 ) : 
                 <div className="verfiySection formsubmit">
                 <p>
                   <Trans>verifycodeJawwalGameszorid</Trans>
                 </p>
                 <input
                   type="text"
                   className="input  gamezoneInput  "
                   value={verifyCode}
                   placeholder={t("Enter_verifycode")}
                   onChange={(e) =>
                     setVerifyCode(e.target.value.replace(/[^0-9]/g, ""))
                   }
                 />
                 <br></br>
                 <br></br> 
                 <button
                   className="snip1562"
                   disabled={verifyCode ? "" : "disabled"}
                   onClick={(e) => {
                     verify(e);
                   }}
                 >
                   {t("Verify")}
                 </button>
               </div>}
              </div>
            </div>
          </>

          {showLoading ? (
            <div className="load">
              <div className="spinerC">
                <div className="spinner"></div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
}

export default withTranslation()(LibyanaMarketing2);
